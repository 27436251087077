import { v4 as uuid } from 'uuid';
import axios from 'axios';
//import ClientJS from 'clientjs';  // this changes the ClientJS object interface
import clientJS from 'clientjs';    // vscode shows unused, but this is needed to init the module.
let envJson = require('./env.json');


const myUuid = uuid();
//const { uuid } = require('uuidv4');
var appUrl = undefined;
var webappUrl = undefined;
var websiteUrl = "https://www.extendedcaresolutions.com";
var assetCdnUrl = "https://assets.prod.aws.extcare.com";
var ecServerUrl = undefined;
var ecServer = undefined;
var currHref = undefined;

var potentialBaseRedirectUrl = undefined;
var potentialOrgId = undefined;
var encounterInitPath = "/vcr-cloud/encounter/init";
var fhirPath = "/api/fhir/launch/";

var options = [];
var optionsParamRegex = new RegExp('&options=', 'ig');

var queryParams = {};

var referrerId = uuid();

var device = null;

var uuidRegexString = "[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}";
var uuidRegex = new RegExp(uuidRegexString, 'ig');
var universalShortLinkRegex = new RegExp('^.*[\./]ecul[\./].*id=' + uuidRegexString + '$','ig');
var universalPathBaseShortLinkRegex = new RegExp('^https://(.*)/ecul/.*id=' + uuidRegexString + '$','ig');
var pathBaseULRegex = new RegExp("^https://(.*)/ecul/.*", 'ig');
var touchEnabled = true;

// polyfills - start
if (!String.prototype.includes) {
	String.prototype.includes = function(search, start) {
	  'use strict';

	  if (search instanceof RegExp) {
		throw TypeError('first argument must not be a RegExp');
	  }
	  if (start === undefined) { start = 0; }
	  return this.indexOf(search, start) !== -1;
	};
}

if (!Array.prototype.includes) {
	Object.defineProperty(Array.prototype, "includes", {
	  enumerable: false,
	  value: function(obj) {
		  var newArr = this.filter(function(el) {
			return el == obj;
		  });
		  return newArr.length > 0;
		}
	});
}
// polyfills - end

function loadJSON(callback) {

	var xobj = new XMLHttpRequest();
	xobj.overrideMimeType("application/json");
	xobj.open('GET', 'env.json', false);
	xobj.send(null);
	callback(xobj.responseText);

}

function parseEnvJson(envJson) {
	var json = envJson;
	var envs = json.environments;
	var href = window.location.hostname;
	var subdomain = href.split('.')[0];
	for (var i = 0; i < envs.length; i++) {
		if (envs[i][subdomain]) {
			potentialBaseRedirectUrl = envs[i][subdomain]["server"];
			if (envs[i][subdomain]["orgId"]) {
				potentialOrgId = envs[i][subdomain]["orgId"];
			}
		}
	}
}

function setCookie(cname, cvalue, minutes) {
	let now = new Date();
	now.setTime(now.getTime() + (minutes*60*1000));
	let expires = "expires="+ now.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires;
}

function getCookie(cname) {
	var name = cname + "=";
	var dcookie = decodeURIComponent(document.cookie);
	var ca = dcookie.split(';');
	for(var i = 0; i <ca.length; i++) {
	  var c = ca[i];
	  while (c.charAt(0) == ' ') {
		c = c.substring(1);
	  }
	  if (c.indexOf(name) == 0) {
		return c.substring(name.length, c.length);
	  }
	}
	return "";
}

function removeCookie(cname) {
	document.cookie = cname + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC";
}

function takeFingerprint(successCallback, errorCallback) {
	axios.get(ecServerUrl + "/vcr-cloud/dl/fingerprint").then(function(response){
		if (response.status === 200) {
			let fingerprintDiv = document.createElement("div");
			fingerprintDiv.innerHTML = response.data;
			document.body.appendChild(fingerprintDiv);
			eval(document.getElementById("fingerprintScript").text);
			successCallback();
		} else {
			errorCallback();
		}
	});
}

function getFingerprint() {
	return document.getElementById("deviceFingerprint").getAttribute("value");
}

function sendFingerprintAndRedirect(appLink, successCallback, errorCallback) {
	axios.put(ecServerUrl + '/vcr-cloud/dl', {
		'appLink' : appLink,
		'referrerId' : referrerId,
		'fingerprint' : getFingerprint()
	}).then(function(response) {
		successCallback();
	}).catch(function(error) {
		errorCallback();
	});
}

function getLinkIdWhenUniversalShortLink() {
	let linkId = null;
	if (universalShortLinkRegex.test(currHref) || universalPathBaseShortLinkRegex.test(currHref)) {
		linkId = currHref.match(uuidRegex);
		setCookie("sul", true, 1);
	}
	return linkId;
}

function detectOperatingSystem() {

	var userAgent = navigator.userAgent || navigator.vendor || window.opera;
	touchEnabled = navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {
		return "Windows Phone";
	}

	if (/android/i.test(userAgent)) {
		return "Android";
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return "iOS";
	}

	if (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) && !window.MSStream) {
		return "iOS";
	}

	return "unknown";
}

function detectOptions() {
	var hrefSeparator = document.createElement('a');
	hrefSeparator.href = currHref;
	var urlParams = hrefSeparator.search.substring(1).split('&');
	for (var i = 0; i < urlParams.length; i++) {
		var param = urlParams[i].split('=');
		var key = param[0] ? param[0].toUpperCase() : undefined;
		if (key === "OPTIONS") {
			options = param[1] ? param[1].toUpperCase().split(',') : [];
		}
		// for backward compatibility
		if (key === "NOREDIRECT") {
			if (param[1] && param[1].toUpperCase() === "TRUE") {
				options.push(key);
			}
		}
	}
}

function registerDevice() {
	currHref = window.location.href;
	setQueryParams();

	if (!queryParams.REGCODE) {
		return true;
	}

	determineEcServerUrl();
	printStateBeforeRedirect();

	let appLink = getQrLink();
	if (getCookie("fingerprinted") === '' && !currHref.includes("fingerprinted")) {
		takeFingerprint(function() {
			sendFingerprintAndRedirect(appLink, function() {
				redirect(appLink);
			}, function() {
				redirect(appLink);
			});
		}, function() {
			redirect(appLink);
		});
	} else {
		redirect(appLink);
	}

	return false;
}

function go() {
	device = new ClientJS();
	currHref = window.location.href;
    parseEnvJson(envJson);
	detectOptions();

	if (options.includes("REJOIN")) {
		displayRejoinOnError("You were disconnected due to loss of network.", "Tap 'Rejoin' to reconnect to your visit:");
	} else {
		// This is for a different ticket.
		// if (!navigator.cookieEnabled) {
		// 	displayError("Your browser-cookies are disabled.  Please enable the browser-cookies to get the best experience for this visit.");
		// 	return;
		// }

		let storeRedirectCount = getCookie("storeRedirectCount");
		let linkId = getLinkIdWhenUniversalShortLink();

		setQueryParams();
		determineEcServerUrl();

        printStateBeforeRedirect();

		if (linkId) {
			axios.get(ecServerUrl + "/vcr-cloud/sl/" + linkId + "/url").then(function(response) {
				if (response.status === 200) {
					if (response.data.status === 'SUCCESS') {
						currHref = response.data.url;
						window.location.replace(currHref);
					}
				} else {
					detectAndDirect();
				}
			})["catch"](function (error) {
				displayError("<span style='font-size: 20px'><b>We're Sorry!</b></span><br/><br/>" + error.response.data.message
				+ " If you need immediate assistance regarding a scheduled video visit appointment, please contact your health care provider. <br/><br/>"
				+ "Thank you for your patience!");
			});;
		} else {
			detectAndDirect();
		}
	}
}

function printStateBeforeRedirect() {

    console.log("ECUL state variables");
    console.log("ecul Query Params: ", queryParams);
    console.log("ecul App Url: ", appUrl);
    console.log("ecul webapp Url: ", webappUrl);
    console.log("ecul ecServerUrl: ", ecServerUrl);
    console.log("ecul ecServer: ", ecServer);
    console.log("ecul currHref: ", currHref);
    console.log("ecul potentialBaseRedirectUrl: ", potentialBaseRedirectUrl);
    console.log("ecul potentialOrgId: ", potentialOrgId);
    console.log("ecul options: ", options);
    console.log("ecul referrerId: ", referrerId);
    console.log("ecul device: ", device);

}

function detectAndDirect() {
	device = new ClientJS();
	let os = detectOperatingSystem();

	if (detectFhir(os)) {
		return;
	}

	if (os !== 'unknown' && typeof queryParams.ORGID != 'undefined' && onMobileDevice(os)) {
		let bypassEUL = queryParams.BYPASSEUL ? "&bypassEUL=true" : "";
		axios.get(ecServerUrl + "/vcr-cloud/supportedClient/" + queryParams.ORGID + "?token=" + (queryParams.TOKEN ? queryParams.TOKEN : queryParams.SJ) + "&tokenFormat=" + queryParams.TOKENFORMAT + "&isTouchEnabled=" + touchEnabled + bypassEUL).then(function(response){
			if (response.status === 200) {

				// HTML content is returned when unsupported browser is detected
				if (response.data.indexOf('VCR Cloud Unsupported Browser') > 0 ) {
					document.write(response.data);
					// adding bypassEUL to break the loop of displaying the unsupported browser page when in enhanced universal link.
					document.getElementById("button-link").href = document.getElementById("button-link").href + "&bypassEUL=true";
					return;
				}

				autoClose();

				if (response.data === 'BROWSER_ONLY' || response.data === 'APP_AND_BROWSER' && typeof queryParams.FULP === 'undefined') {
					redirect(response.data);
				}

				let appLink = getCallLink("mobile");
				if (getCookie("fingerprinted") === '' && !currHref.includes("fingerprinted")) {
					takeFingerprint(function() {
						sendFingerprintAndRedirect(appLink, function() {
							redirect(appLink);
						}, function() {
							redirect(appLink);
						});
					}, function() {
						redirect(appLink);
					});
				} else {
					redirect(appLink);
				}
			} else {
				autoClose();
				redirect(appLink);
			}
		});

	} else {
		redirect();
	}
}

// Detects FHIR flow using universal Link and will hand-off to the server to handle supported clients.
function detectFhir(os) {
	let isPathBased = pathBaseULRegex.test(currHref);
	// The serverPath parameter (set by a AWS lambda function) needs to be checked for FHIR UL as the url will change.
	if ((currHref.indexOf(fhirPath) > 0 || (queryParams.SERVERPATH && decodeURIComponent(queryParams.SERVERPATH).indexOf(fhirPath) > 0)) && currHref.indexOf("ecul") > 0 && queryParams.ORGID && (queryParams.SERVER || queryParams.ECSERVERIP)) {
		let fhirRedirectionUrl = determineFhirRedirectUrl();
		if (queryParams.SERVERPATH) {
			fhirRedirectionUrl = fhirRedirectionUrl.replace(isPathBased ? "/ecul/index.html" : "index.html", "");
		}
		if (onMobileDevice(os) && getCookie("fingerprinted") === '' && !currHref.includes("fingerprinted")) {
			takeFingerprint(function() {
				let appLink = window.location.href.replace("https", "extcarecloud");
				appLink = removeServerPathParam(appLink);
				fhirRedirectionUrl += "&referrer=" + referrerId;
                if (!appLink.includes("orgId="+queryParams.ORGID)) {
                    appLink += "&orgId=" + queryParams.ORGID;
                }
				sendFingerprintAndRedirect(appLink, function() {
					window.location.replace(fhirRedirectionUrl);
				}, function() {
					window.location.replace(fhirRedirectionUrl);
				});
			}, function() {
				window.location.replace(fhirRedirectionUrl);
			});
		} else {
			window.location.replace(fhirRedirectionUrl);
		}
		return true;
	}
	return false;
}

function determineFhirRedirectUrl() {
	let storeRedirectCount = getCookie("storeRedirectCount");
	let fhirRedirectCount = countFhirRedirect();
	let fhirRedirectionUrl = currHref.replace(window.location.hostname, (queryParams.SERVER ? queryParams.SERVER : queryParams.ECSERVERIP) + "/vcr-cloud" + fhirPath + queryParams.ORGID);
	fhirRedirectionUrl = fhirRedirectionUrl.replace("#selftest", ""); // temporarily remove the hash
	return fhirRedirectionUrl + "&appInstalled=" + (storeRedirectCount ? "true" : "false") + "&isTouchEnabled=" + touchEnabled + "&fhirRedirectCount=" + fhirRedirectCount + window.location.hash;
}

// remove serverPath that can mess up the selftest links
function removeServerPathParam(url) {
	return url.replace("&serverPath=" + queryParams.SERVERPATH, "");
}

function onMobileDevice(os) {
	return device.isMobile() || device.getDeviceType() === 'tablet' || ['iOS', 'Android'].includes(os);
}

function autoClose() {
	// Note: Attempt to close the browser tab.  Closing a tab is not allowed if it's not opened
	// using a script or is not clicked from an anchor.
	// Safari doesn't allow closing the tab even if it's opened from an anchor.  However, it does allow
	// the tab to be closed if the the link is from an email.
	let autoCloseWaitTime = 60000 * 2;
	if (performance.navigation.type != performance.navigation.TYPE_NAVIGATE) {
		setTimeout(function() {
			displayCloseBrowserMessage();
			window.close();
		}, autoCloseWaitTime);
		let storeRedirectCount = getCookie("storeRedirectCount");
		if (parseInt(storeRedirectCount) === 1) {
			displayCloseBrowserMessage();
		}
	} else {
		setTimeout(function() {
			if (device.getBrowser().includes("Safari"))  {
				displayCloseBrowserMessage();
			}
			window.close();
		}, autoCloseWaitTime);
	}
}

// TODO: Remove the ambiguity of the link param
function redirect(link) {
	var os = detectOperatingSystem();
	var appStore = "https://itunes.apple.com/app/id1457828757";
	var playStore = "market://details?id=com.extcare.vcr.cloud.android&referrer=";

	if (!options.includes('NOREDIRECT')) {
		console.log("Will redirect to store or web-based link");
		if (os == "Android") {
			tryAlternatives(link, playStore + referrerId);
			showStoreImage(playStore + referrerId, `${assetCdnUrl}/images/google-play-badge.png`, "margin-top: -9px;");
		}
		if (os == "iOS") {
			tryAlternatives(link, appStore);
			showStoreImage(appStore, `${assetCdnUrl}/images/app-store-badge.svg`, "");
		}
		if (os == "unknown") {
			window.location.replace(getCallLink("web"));
		}
	} else {
		console.log("noRedirect: navigate to landing page");

		document.getElementById("app").style.display = "block";

		appUrl = getCallLink("mobile");
		webappUrl = getCallLink("web");

		displayAlternative(appUrl, "", "<a href='" + webappUrl + "'>Proceed</a> using your mobile browser.", "Or tap 'Join'", "Join");
	}
}

// This function displays the re-association page when the UL association with the app
// is broken.
function tryAlternatives(appLink, appStoreLink) {

	if (clientOptionsRedirect(appLink)) {
		// prevent unnecessary showing of re-association page
		return;
	}

	let needMessage = "The <b>'ExtendedCare Cloud'</b> app needs to be installed.<br/>";
	let continueMessage = "Tap 'Continue' if you already<br/>have the app installed.";
	let actionText = "Continue"
	let visitPlayStoreMessage = "Please visit the " + determineStore(appStoreLink) + "<br/> to install it.";

	let redirectCount = countStoreRedirect();
	let storeRedirectLimit = getCookie("storeRedirectLimit");

	if (!storeRedirectLimit) {
		storeRedirectLimit = 2;
	}

	if (redirectCount <= storeRedirectLimit) {
		window.location.replace(appStoreLink);
	} else {
		if (redirectCount > storeRedirectLimit) {
			if (device.getBrowser().includes("Safari")) {
				displayAlternative(appLink, needMessage, visitPlayStoreMessage, continueMessage, actionText);
				if (getCookie("sul")) {
					removeCookie("sul");
					window.location.reload();
				}
			} else {
				displayAlternative(appLink, needMessage, visitPlayStoreMessage, continueMessage, actionText);
			}
		}
	}
}

function clientOptionsRedirect(supportedClient) {
	if (!['APP_ONLY', 'BROWSER_ONLY', 'APP_AND_BROWSER'].includes(supportedClient)) {
		return false;
	}

	let redirectCount = getCookie("storeRedirectCount");
	let token = queryParams.TOKEN ? queryParams.TOKEN : queryParams.SJ;

	// this allows the use of mobile browser for visit
	// this means that either the app has not been installed and supported client is 'BROWSER_ONLY' or 'APP_AND_BROWSER'
	// or the app has been installed and supported client is 'BROWSER_ONLY'
    if ((redirectCount === '' && supportedClient !== 'APP_ONLY') || (redirectCount !== '' && supportedClient === 'BROWSER_ONLY')) {
		window.location.replace(ecServerUrl + "/vcr-cloud/encounter/init/" + queryParams.ORGID + "?token=" + token  + "&tokenFormat=" + queryParams.TOKENFORMAT);
		return true;
	}

	if (redirectCount !== '' && supportedClient !== 'APP_ONLY') {
		window.location.replace(ecServerUrl + "/vcr-cloud/sl/clientOptions/" + queryParams.ORGID + "?token=" + token + "&tokenFormat=" + queryParams.TOKENFORMAT + "&fulp=appScheme");
	}

	return true;
}

function determineStore(appStoreLink) {
	return appStoreLink.includes("market:") ? "Play Store" : "App Store";
}

function showStoreImage(appStoreLink, badge, style) {
	document.getElementById("store-logo-image").onclick = function() {
		window.location.replace(appStoreLink);
	}
	document.getElementById("store-logo-image").src = badge;
	document.getElementById("store-logo-image").style = style;
}

function displayAlternative(appLink, message1, message2, message3, buttonText) {
	document.getElementById("alternative1").style.display = "block";
	document.getElementById("message1").innerHTML = message1;
	document.getElementById("button-link").href = appLink;
	document.getElementById("button-text").innerHTML = buttonText;
	document.getElementById("alternative2").style.display = "block";
	document.getElementById("message2").innerHTML = message2;
	if (message3) {
		document.getElementById("alternative3").style.display = "block";
		document.getElementById("message3").innerHTML = message3;
	}
	document.getElementById("store-logo").style.display = "block";
	document.getElementById("join-button").style.display = "block";
	document.getElementById("app").style.display = "block";
}

function displayRejoinOnError(message1, message2) {
	document.getElementById("rejoin-button-link").href = "extcarecloud://rejoin";
	document.getElementById("rejoin-button-text").innerHTML = "Rejoin";
	document.getElementById("alternative1").style.display = "block";
	document.getElementById("message1").innerHTML = message1;
	document.getElementById("rejoin").style.display = "block";
	document.getElementById("rejoin-message").innerHTML = message2;
	document.getElementById("rejoin-button").style.display = "block";
	document.getElementById("app").style.display = "block";
}

function displayCloseBrowserMessage() {
	document.getElementsByClassName("choices")[0].style.display = "none";
	document.getElementById("alternative1").style.display = "block";
	document.getElementById("message1").style = "color: #46575F;";
	document.getElementById("message1").innerHTML = "<b>ExtendedCare Cloud</b><br/><br/>You may close this page.";
	document.getElementById("app").style.display = "block";
}

function displayError(message) {
	document.getElementById("alternative1").style.display = "block";
	document.getElementById("message1").innerHTML = message;
	document.getElementById("message1").style = "color: #46575F;";
	document.getElementById("app").style.display = "block";
}

function countStoreRedirect(value) {
	// The storeRedirectCount is set during the client finger printing.
	// Its value is set in system config ECUL_PAGE_CONFIG's storeRedirectLimit.
	let storeRedirectCount = value ? value : getCookie("storeRedirectCount");

	if (storeRedirectCount === "") {
		storeRedirectCount = 1;
	} else {
		storeRedirectCount = parseInt(storeRedirectCount);
		storeRedirectCount++;
	}

	// The useAppSchemeExp is set during the client finger printing.
	// Its value is set in system config ECUL_PAGE_CONFIG's useAppSchemeDuration.
	let storeRedirectCountExp = getCookie("useAppSchemeExp");
	if (value === 0) {
		storeRedirectCount = 1;
	} else if (!storeRedirectCountExp) {
		storeRedirectCountExp = 1440;
	}

	setCookie("storeRedirectCount", storeRedirectCount, storeRedirectCountExp);
	return storeRedirectCount;
}

function countFhirRedirect() {
	let fhirRedirectCount = getCookie("fhirRedirectCount");
	if (fhirRedirectCount == "") {
		fhirRedirectCount = 1;
	} else {
		fhirRedirectCount = parseInt(fhirRedirectCount);
		fhirRedirectCount++;
	}
	setCookie("fhirRedirectCount", fhirRedirectCount, 2);
	return fhirRedirectCount;
}

function determineEcServerUrl() {
	ecServer = queryParams.ECSERVERIP ? queryParams.ECSERVERIP : queryParams.SERVER;
	ecServer = ecServer ? ecServer : (pathBaseULRegex.test(currHref) ? window.location.hostname : potentialBaseRedirectUrl);
	ecServerUrl = decodeURIComponent("https://" + ecServer);
}

function setQueryParams() {
	var urlParams = window.location.search.substring(1).split('&');
	for (var i = 0; i < urlParams.length; i++) {
		var pair = urlParams[i].split('=');
		queryParams[pair[0].toUpperCase()] = pair[1] || '';
	}
    /*
     * orgId is required in most contexts and if it is
     * found via query params then attempt to access
     * via the token prefix and if it is still not
     * accessed then attempt from the path
     */
    getOrgIdFromToken();
    getOrgIdFromPath();
}

function getOrgIdFromToken() {
    if (!queryParams.ORGID) {
        var orgId = "";
        var pair = queryParams.TOKEN ? queryParams.TOKEN : queryParams.SJ;
        try {
            var metadata = pair.split('.');
            if (metadata && metadata.length == 2) {
                var decoded = window.atob(metadata[0]);
                pair = decoded.split(',');
                if (pair && pair.length == 2) {
                    orgId = pair[0];
                }
            }
            queryParams.ORGID = orgId;
        } catch (error) {
            console.log("Unable to access orgId from token: ", pair);
        }
    }
}

function getOrgIdFromPath() {
    if (!queryParams.ORGID) {
        try {
            // to support UL and SoF the window.location path
            // will be stripped and added to SERVERPATH url
            // parameter
            var urlpath = decodeURIComponent(queryParams.SERVERPATH);
            var pathparts = urlpath.split('/');
            var lastpart = pathparts.pop();
            queryParams.ORGID = lastpart;
        } catch (error) {
            console.log("Unable to access orgId from path: ", queryParams.SERVERPATH);
        }

    }
}

function getQrLink() {
	var newHref = [];
	var hrefSeparator = document.createElement('a');
	hrefSeparator.href = currHref;	
	newHref.push("extcarecloud:", "//", hrefSeparator.search);
	return newHref.join("");
}

function getCallLink(type) {
	var newHref = [];

	if (options.includes('ULINK')) {
		// ignore options param when generating call link
		currHref = currHref.replace(optionsParamRegex, '&o=');
	}

	var hrefSeparator = document.createElement('a');
	hrefSeparator.href = currHref;
	var hashElement = hrefSeparator.hash;
	var protocol = hrefSeparator.protocol;

	if (type === "web" || type === "clientOptions") {
		newHref.push(protocol, "//");
	} else {
		if (options.includes('ULINK')) {
			newHref.push(currHref);
		} else {
			newHref.push("extcarecloud:", "//");
		}
	}

	if ((type === 'mobile' && !options.includes('ULINK')) || type === "web" || type === "clientOptions") {
		let serverPath = queryParams.SERVERPATH;
		let orgId = queryParams.ORGID ? queryParams.ORGID : "";
		var baseUrl = ecServer

		if (potentialOrgId) {
			orgId = potentialOrgId;
		}

		if (baseUrl == undefined && currHref.includes('/ecul/')) {
			baseUrl = hrefSeparator.hostname;
		}

		if (baseUrl !== undefined) {

			let path = encounterInitPath;
			if (serverPath) {
				path = serverPath.replace(/^\/ecul\//i, '');
				orgId = "";
			}

			newHref.push(decodeURIComponent(baseUrl), decodeURIComponent(path), "/", orgId, "?");
			newHref.push(hrefSeparator.search.substring(1));
			newHref.push("&isTouchEnabled=", touchEnabled);
			if (hashElement) {
				newHref.push(hashElement);
			}
		} else {
			newHref = [];
			newHref.push(websiteUrl);
		}
	}

	var link = newHref.join("");
	console.log("generated link: " + link);
	return link;

}
/*
  webpack Test only function--can bypass
*/
function testProbes(){
        device = new ClientJS();

        console.log("device: ", device)
        console.log("device OS: ", device.getOS());
        console.log("device includes Safari: ", device.getBrowser().includes("Safari"));
        console.log("device type: ", device.getDeviceType())
        console.log("device isMobile: ", device.isMobile())
        return true;
}
document.addEventListener("DOMContentLoaded",function(){
   testProbes() && registerDevice() && go();
});
